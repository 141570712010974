#root{
    height: 100vh;
}

.containerFormLogin{
    height: 100%;
    flex: 1;
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.error-message {
    color: #DC3545;
}